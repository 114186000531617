import React from "react";
import { motion } from "framer-motion";
import imgMembership from "../assets/images/img-membership.jpg";

function Membership() {
  return (
    <div className="App-section App-membership">
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 0.5, ease: "easeInOut" }}
        viewport={{ once: true }}
        className="App-section-body"
      >
        <div className="img-membership-wrap">
          {" "}
          <img
            className="img-membership"
            src={imgMembership}
            alt="img-community"
          />
        </div>
        <div className="App-membership-pages">
          <div className="App-membership-page App-membership-page-intro">
            <h2 className="App-section-title">
              <span className="text-green-glow">Game Boss</span>
              <div className="text-stroke">Membership</div>
            </h2>
            <p className="events-text">
              At Game Boss, community is everything. Engage with fellow gamers
              through our forums, join or create guilds, and participate in
              collaborative projects that make our café a vibrant and dynamic
              space.
            </p>
          </div>
          <div className="App-membership-page App-membership-page-cards">
            <div className="membership-wrap">
              <div className="card card-borderGradient card-membership">
                <div className="card-body">
                  <h4 className="card-title  text-gradient text-gradient-grey">
                    Basic
                  </h4>
                  <div className="card-text">
                    Access to standard gaming stations, participation in
                    community events, and a 10% discount on food and beverages.
                  </div>
                </div>
              </div>
              <div className="card card-borderGradient card-membership">
                <div className="card-body">
                  <h4 className="card-title  text-gradient text-gradient-gold">
                    Premium
                  </h4>
                  <div className="card-text">
                    Enjoy all Basic benefits plus priority booking, a 20%
                    discount on merchandise, and exclusive invites to premium
                    events.
                  </div>
                </div>
              </div>

              <div className="card card-borderGradient card-membership">
                <div className="card-body">
                  <h4 className="card-title   text-gradient text-gradient-green">
                    VIP Boss
                  </h4>
                  <div className="card-text">
                    All Premium benefits plus access to private gaming rooms,
                    personalized services, a 30% discount on all purchases, and
                    early access to new features and tournament
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>{" "}
    </div>
  );
}

export default Membership;
