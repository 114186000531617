import React from "react";
import { motion } from "framer-motion";
import imgform from "../assets/images/img-community-form.jpg";
import imgEngage from "../assets/images/img-community-engage.jpg";
import imgMember from "../assets/images/img-community-member.jpg";
import imgDiscussion from "../assets/images/img-community-discussion.jpg";
import imgCommunity from "../assets/images/img-community.jpg";

function Community() {
  return (
    <div className="App-section App-community">
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 0.65, ease: "easeInOut" }}
        viewport={{ once: true }}
        className="App-section-body"
      >
        {" "}
        <div className="img-community-wrap">
          {" "}
          <img
            className="img-community"
            src={imgCommunity}
            alt="img-community"
          />
        </div>
        <div className="App-community-pages">
          <div className="App-community-page  App-community-page-intro">
            <h2 className="App-section-title">
              <span className="text-green-glow">Game Boss</span>
              <div className="text-stroke">Community</div>
            </h2>
            <p className="community-text">
              At Game Boss, community is everything. Engage with fellow gamers
              through our forums, join or create guilds, and participate in
              collaborative projects that make our café a vibrant and dynamic
              space.
            </p>
          </div>
          <div className="App-community-page  App-community-page-wrap">
            <div className="Community-wrap">
              <div className="card card-borderGradient card-community">
                <img src={imgform} alt="img-community"></img>
                <div className="card-body">
                  <h6 className="card-title">Form Your Guild</h6>
                  <div className="card-text">
                    Whether you’re forming a competitive team or a casual gaming
                    group, our guild system provides the tools you need to
                    manage members, schedule events, and compete together. Build
                    your team, strategize your gameplay, and rise through the
                    ranks as a united force
                  </div>
                </div>
              </div>
              <div className="card card-borderGradient card-community">
                <img src={imgEngage} alt="img-community"></img>
                <div className="card-body">
                  <h6 className="card-title">Engage in Projects</h6>
                  <div className="card-text">
                    Participate in collaborative initiatives such as game
                    development clubs, charity streams, and more. Your
                    contributions help shape the Game Boss community and make a
                    positive impact in the gaming world.
                  </div>
                </div>
              </div>
              <div className="card card-borderGradient card-community">
                <img src={imgMember} alt="img-community"></img>
                <div className="card-body">
                  <h6 className="card-title">Member Profiles</h6>
                  <div className="card-text">
                    Create and customize your member profile to showcase your
                    gaming achievements, favorite games, and connect with other
                    members. Share your journey, track your progress, and build
                    lasting friendships within the Game Boss community.
                  </div>
                </div>
              </div>{" "}
              <div className="card card-borderGradient card-community">
                <img src={imgDiscussion} alt="img-community"></img>
                <div className="card-body">
                  <h6 className="card-title">Discussion Boards</h6>
                  <div className="card-text">
                    Join our vibrant forums and discussion boards to share
                    strategies, discuss the latest games, seek advice, and
                    engage in lively conversations with fellow gamers. Whether
                    you’re a newbie or a seasoned pro, there’s a place for you
                    here.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>{" "}
    </div>
  );
}

export default Community;
