import React, { useRef, useState } from "react";
import {
  motion,
  useScroll,
  useTransform,
  useSpring,
  useMotionValueEvent,
} from "framer-motion";
import { Canvas } from "@react-three/fiber";

function UseCases() {
  const [rostateBox, setrostateBox] = useState(1);
  const scrollRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: scrollRef,
    offset: ["start end", "end start"],
  });
  const scaleSpring = useSpring(scrollYProgress, {
    stiffness: 120,
    damping: 30,
    restDelta: 0.001,
  });
  const rotateY = useTransform(scaleSpring, [0.2, 0.7], [0, 1100]);
  useMotionValueEvent(rotateY, "change", (latest) => {
    setrostateBox(Math.round(latest));
    console.log(rostateBox);
  });
  return (
    <motion.div className="App-section App-useCases" ref={scrollRef}>
      <motion.div className="App-section-body">
        <motion.div className="canvas">
          <motion.div className="page page-2" style={{ z: rotateY }}>
            <h3>Page 1</h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
          </motion.div>
          <motion.div className="page page-1" style={{ z: rotateY }}>
            {" "}
            <h3>Page 2</h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
          </motion.div>
        </motion.div>
      </motion.div>
    </motion.div>
  );
}

export default UseCases;
