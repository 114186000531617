import React, { useRef } from "react";
import imgAbout from "../assets/images/img-aboutBanner.jpg";
import imgAboutCaharcter from "../assets/images/img-aboutCharacter.png";
import imgLineArt from "../assets/images/img-linesArt.png";
import { motion, useScroll, useTransform, useSpring } from "framer-motion";

function About() {
  const scrollRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: scrollRef,
    offset: ["start end", "end end"],
  });
  const scaleSpring = useSpring(scrollYProgress, {
    stiffness: 120,
    damping: 30,
    restDelta: 0.001,
  });
  const blueBoxTransform = useTransform(scaleSpring, [0, 0.8], ["0px", "0px"]);
  return (
    <motion.div className="App-section App-about" ref={scrollRef}>
      <motion.div
        className="App-section-body"
        initial={{ opacity: 0, x: 100 }}
        whileInView={{ opacity: 1, x: 0 }}
        transition={{ duration: 1, ease: "easeInOut" }}
        viewport={{ once: true, amount: 0.5 }}
      >
        <div className="App-about-left">
          <img className="about-artLine" src={imgLineArt} alt="img-about" />
          <h2 className="App-section-title text-white">
            <div className="text-stroke">Evolved</div>
            Our Story
          </h2>
          <p className="about-text">
            Game Boss was founded with a vision to create a haven for gamers,
            where community and cutting-edge technology converge. Inspired by
            our partnership with TGC, we aim to revolutionize the gaming café
            experience by prioritizing player interactions and providing
            state-of-the-art facilities. Whether you are a casual gamer or a
            competitive pro, Game Boss is designed to cater to all your gaming
            needs.
          </p>
        </div>
        <motion.div
          className="about-blueBox"
          style={{ y: blueBoxTransform }}
        ></motion.div>
        <div className="about-impact text-stroke">IMPACT</div>
        <img className="img-about" src={imgAbout} alt="img-about" />
        <img
          className="img-aboutCharacter"
          src={imgAboutCaharcter}
          alt="img-about"
        />
      </motion.div>
    </motion.div>
  );
}

export default About;
