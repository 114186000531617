import React from "react";
import { motion } from "framer-motion";
import imgOfferings from "../assets/images/img-gameOffering.png";

function Offerings() {
  return (
    <div className="App-section App-offerings">
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 0.65, ease: "easeInOut" }}
        viewport={{ once: true }}
        className="App-section-body"
      >
        <div className="offerings-wrap">
          <div class="offerings-greyBox"></div>
          <img
            className="img-Offerings"
            src={imgOfferings}
            alt="img-Offerings"
          />
          <h2 className="App-section-title">
            Game Boss
            <div className="text-stroke text-stroke-black">Offerings</div>
          </h2>
          <div className="card card-offerings">
            <div className="card-body">
              <h6 className="card-title">
                High-End<br></br>Hardware
              </h6>
              <div className="card-text">
                Dive into immersive gameplay with our top-tier PCs, consoles,
                and VR setups. Whether you’re into competitive esports or casual
                gaming, our equipment is optimized to deliver smooth and
                exceptional performance.
              </div>
            </div>
          </div>
          <div className="card card-offerings">
            <div className="card-body">
              <h6 className="card-title">
                Seamless<br></br>Cloud Gaming
              </h6>
              <div className="card-text">
                Powered by TGC’s AI-driven cloud gaming platform, Game Boss
                offers you the flexibility to game anytime, anywhere. Enjoy
                reduced latency, access to a vast game library, and effortless
                device switching, ensuring you never miss a moment of action.
              </div>
            </div>
          </div>
          <div className="card card-offerings">
            <div className="card-body">
              <h6 className="card-title">
                Exclusive<br></br>Amenities
              </h6>
              <div className="card-text">
                Fuel your gaming sessions with our delicious food and beverages,
                designed to keep you energized. Relax in our comfortable
                lounges, create and stream content at our dedicated streaming
                stations, or book a private room for your next big tournament.
              </div>
            </div>
          </div>
          <div className="card card-offerings">
            <div className="card-body">
              <h6 className="card-title">
                Streaming and Content <br></br>Creation Stations
              </h6>
              <div className="card-text">
                Unleash your creativity with our state-of-the-art streaming and
                content creation stations. Equipped with high-quality cameras,
                microphones, and broadcasting tools, Game Boss provides
                everything you need to produce professional-grade content.
              </div>
            </div>
          </div>{" "}
          <div className="card card-offerings">
            <div className="card-body">
              <h6 className="card-title">
                VR<br></br>Zones
              </h6>
              <div className="card-text">
                Step into new realities with our dedicated VR zones. Experience
                the latest in virtual reality gaming with cutting-edge VR
                headsets and immersive setups that transport you to different
                worlds.
              </div>
            </div>
          </div>
        </div>
      </motion.div>{" "}
    </div>
  );
}

export default Offerings;
