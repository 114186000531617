import React, { useRef } from "react";
import { motion, useScroll, useTransform, useSpring } from "framer-motion";
import imgHeaderCharacterBg from "../assets/images/img-headerCharacterBg.png";
import imgHeaderCharacter from "../assets/images/img-headerCharacter.png";
import imgHeaderCharacter2 from "../assets/images/img-headerCharacter-2.png";
import imgHeaderCharacter3 from "../assets/images/img-headerCharacter-3.png";
import imgHeaderLogo from "../assets/images/img-logo-bg.png";
function Header() {
  const scrollRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: scrollRef,
    offset: ["start end", "end start"],
  });
  const scaleSpring = useSpring(scrollYProgress, {
    stiffness: 120,
    damping: 30,
    restDelta: 0.001,
  });
  const topHeight = useTransform(scaleSpring, [0.3, 0.35], ["100vh", "0vh"]);

  const img1Opacity = useTransform(scaleSpring, [0.2, 0.3], ["0", "1"]);
  const img1Scale = useTransform(scaleSpring, [0.2, 0.3], [".8", "1"]);
  const img1Move = useTransform(scaleSpring, [0.2, 0.3], ["0", "0px"]);
  const img2Opacity = useTransform(scaleSpring, [0.3, 0.38], ["0", "1"]);
  const img2Scale = useTransform(scaleSpring, [0.3, 0.4], ["1", ".75"]);
  const img2Move = useTransform(scaleSpring, [0.3, 0.4], ["0", "-25vw"]);
  const img3Opacity = useTransform(scaleSpring, [0.4, 0.5], ["0", "1"]);
  const img3Scale = useTransform(scaleSpring, [0.4, 0.5], ["1", ".75"]);
  const img3Move = useTransform(scaleSpring, [0.4, 0.5], ["0", "25vw"]);
  const imgsBgMove = useTransform(scaleSpring, [0, 1], ["150px", "-50px"]);
  const imgsBgOpacity = useTransform(scaleSpring, [0.35, 0.5], ["1", "1"]);
  const title1Opacity = useTransform(scaleSpring, [0.5, 0.85], ["1", "1"]);
  const title2Opacity = useTransform(scaleSpring, [0.5, 0.55], ["1", "1"]);
  const title1Transform = useTransform(
    scaleSpring,
    [0.25, 0.45],
    ["0px", "0px"]
  );
  const title2Transform = useTransform(
    scaleSpring,
    [0, 1],
    ["250px", "-150px"]
  );

  return (
    <motion.header
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{ duration: 1, ease: "easeInOut" }}
      viewport={{ once: true }}
      className="App-header"
      ref={scrollRef}
    >
      <motion.span className="header-backdrop" style={{ height: topHeight }}>
        {" "}
        <div className="header-backdrop-logo">
          <span className="header-backdrop-logo-welcome">Welcome to</span>
          <div
            className="header-backdrop-logo-text glitch"
            data-text="GAME BOSS"
          >
            Game <span className="text-green">Boss</span>
          </div>
          <span className="header-backdrop-logo-img1">
            <img className="header-logo-img" src={imgHeaderLogo}></img>
          </span>
          <div className="header-scroll">
            <div className="text">Scroll Down</div>
            <div class="chevron"></div>
            <div class="chevron"></div>
            <div class="chevron"></div>
          </div>
        </div>{" "}
      </motion.span>{" "}
      <div className="header-character-wrap">
        <motion.img
          src={imgHeaderCharacterBg}
          alt="img-header"
          className="header-character-bg"
          style={{
            translateY: imgsBgMove,
            opacity: imgsBgOpacity,
          }}
        />{" "}
        <motion.div
          className="header-title header-title-2"
          style={{ opacity: title2Opacity, translateY: title2Transform }}
        >
          <h3 className="header-title-top">UNLock Your Full</h3>
          <h2 className="header-title-bottom">Potential</h2>
        </motion.div>{" "}
        <motion.img
          src={imgHeaderCharacter2}
          alt="img-header"
          className="header-character header-character-2"
          style={{
            opacity: img2Opacity,
            scale: img2Scale,
            translateX: img2Move,
          }}
        />
        <motion.img
          src={imgHeaderCharacter3}
          alt="img-header"
          className="header-character header-character-3"
          style={{
            opacity: img3Opacity,
            scale: img3Scale,
            translateX: img3Move,
          }}
        />{" "}
        <motion.img
          src={imgHeaderCharacter}
          alt="img-header"
          className="header-character header-character-1"
          style={{
            opacity: img1Opacity,
            scale: img1Scale,
            translateY: img1Move,
          }}
        />
        <motion.div
          className="header-title header-title-stroke-1"
          style={{ opacity: title1Opacity, translateY: title1Transform }}
        >
          <h3 className="header-title-top text-stroke">Welcome TO</h3>
          <h2 className="header-title-bottom  text-stroke">GAME BOSS</h2>
        </motion.div>
        <motion.div
          className="header-title header-title-stroke-2"
          style={{ opacity: title2Opacity, translateY: title2Transform }}
        >
          <h3 className="header-title-top text-stroke">UNLock Your Full</h3>
          <h2 className="header-title-bottom  text-stroke">Potential</h2>
        </motion.div>
      </div>
    </motion.header>
  );
}

export default Header;
