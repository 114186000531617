import React from "react";
import { useRef } from "react";
import { motion, useScroll, useTransform, useSpring } from "framer-motion";
import imgWeeklyEvent from "../assets/images/img-weeklyTournament.png";
import imgMonthlyEvent from "../assets/images/img-monthlyTournament.png";
import imgSpecialEvent from "../assets/images/img-specialTournament.png";

function Events() {
  var pageShiftTime = window.innerWidth < 700 ? 0.6 : 0.6;
  const scrollRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: scrollRef,
    offset: ["start end", "end start"],
  });
  const scaleSpring = useSpring(scrollYProgress, {
    stiffness: 120,
    damping: 30,
    restDelta: 0.001,
  });
  const moveCards = useTransform(scaleSpring, [0.35, 0.6], ["0px", "-100dvw"]);
  return (
    <motion.div className="App-section App-events" ref={scrollRef}>
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 1, ease: "easeInOut" }}
        viewport={{ once: true, amount: 0.5 }}
        className="App-section-body"
      >
        <motion.div
          className="App-events-pages"
          style={{ translateX: moveCards }}
        >
          <div className="App-events-page App-events-page-intro">
            <h2 className="App-section-title">
              <div className="text-stroke">Events</div>
              <span className="text-green-glow">Tournaments</span>
            </h2>
            <p className="events-text">
              Ready to showcase your skills? Participate in our competitive
              leagues and stand a chance to win amazing prizes. Whether you’re a
              seasoned pro or a new gamer, our tournaments are designed for all
              skill levels.
            </p>
          </div>
          <div className="App-events-page">
            <div className="events-wrap">
              <motion.div className="card card-borderGradient card-event">
                <img src={imgWeeklyEvent} alt="img-event"></img>
                <div className="card-body">
                  <h6 className="card-title">Weekly Tournaments</h6>
                  <div className="card-text">
                    Compete in our upcoming weekly leagues for popular games
                    like League of Legends, CS & Fortnite. Climb the ranks and
                    earn exclusive rewards.
                  </div>
                </div>
              </motion.div>
              <motion.div className="card card-borderGradient card-event">
                <img src={imgMonthlyEvent} alt="img-event"></img>
                <div className="card-body">
                  <h6 className="card-title">Monthly Championship</h6>
                  <div className="card-text">
                    Test your skills in our monthly championships with larger
                    prize pools and intense competition. Gather your team and
                    aim for the top!
                  </div>
                </div>
              </motion.div>
              <motion.div className="card card-borderGradient card-event">
                <img src={imgSpecialEvent} alt="img-event"></img>
                <div className="card-body">
                  <h6 className="card-title">Special Theme Tournaments</h6>
                  <div className="card-text">
                    Join our upcoming themed tournaments celebrating special
                    occasions, game releases, and community milestones. Enjoy
                    unique challenges and exclusive prizes.
                  </div>
                </div>
              </motion.div>
            </div>
          </div>
        </motion.div>
      </motion.div>{" "}
    </motion.div>
  );
}

export default Events;
