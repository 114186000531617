import React, { useState } from "react";
import imgLineArt from "../assets/images/img-linesArt.png";
import imgArrowDown from "../assets/images/img-arrow-down.png";
import imgGameBoss from "../assets/images/img-gameBoss-black.png";
import { useRef } from "react";
import { motion, useScroll, useSpring, useTransform } from "framer-motion";
function Footer() {
  const [emailSent, setEmailSent] = useState(false);
  const [responseText, setResponseText] = useState("");
  const [modal, setmodal] = useState(null);
  const [signupEmail, setSignupEmail] = useState("");
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

  const [contactUs, setContactUs] = useState([
    {
      fullName: "",
      email: "",
      message: "",
      subject: "",
    },
  ]);
  const moveRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: moveRef,
    offset: ["start end", "end start"],
  });
  const scaleXSpring = useSpring(scrollYProgress, {
    stiffness: 100,
    damping: 30,
    restDelta: 0.001,
  });

  const handleEmailSignup = async (e) => {
    e.preventDefault();
    try {
      const headersList = { "Content-Type": "application/json" };
      const bodyContent = JSON.stringify({
        email: signupEmail,
      });
      const response = await fetch("https://app.gameboss.io/api/signup", {
        method: "POST",
        body: bodyContent,
        headers: headersList,
      });
      const responseMessage = await response.json();
      if (!response.ok) {
        setEmailSent(true);
        setSignupEmail("");
        setResponseText(responseMessage.error);
        const message = response.status;
        throw new Error(message);
      } else {
        setEmailSent(true);
        setSignupEmail("");
        setResponseText(responseMessage.message);
      }
    } catch (error) {
      console.log("There was an error!", error);
    }
  };

  const handleContactUsSubmit = async (e) => {
    e.preventDefault();
    try {
      const headersList = {
        Accept: "*/*",
        "User-Agent": "Thunder Client (https://www.thunderclient.com)",
        "Content-Type": "application/json",
      };

      const bodyContent = JSON.stringify({
        fullName: contactUs.fullName,
        email: contactUs.email,
        message: contactUs.message,
        subject: contactUs.subject,
      });

      const response = await fetch("https://app.gameboss.io/api/contact", {
        method: "POST",
        body: bodyContent,
        headers: headersList,
      });
      const responseMessage = await response.json();

      if (!response.ok) {
        setEmailSent(true);
        setSignupEmail("");
        setResponseText(responseMessage.error);
        const message = response.status;
        throw new Error(message);
      } else {
        setEmailSent(true);
        setSignupEmail("");
        setResponseText(responseMessage.message);
        setContactUs({
          fullName: "",
          email: "",
          message: "",
          subject: "",
        });
      }
    } catch (error) {
      //console.log("There was an error!", error);
    }
  };
  return (
    <div className="App-section App-footer">
      {" "}
      {emailSent && (
        <div className="alert alert-success alert-dismissible alert-email">
          <div>{responseText}</div>
          <button
            type="button"
            className="btn btn-close"
            data-bs-dismiss="alert"
            aria-label="Close"
            onClick={() => setEmailSent(false)}
          >
            {" "}
            <i class="fa-solid fa-xmark"></i>{" "}
          </button>
        </div>
      )}
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 0.65, ease: "easeInOut" }}
        viewport={{ once: true }}
        className="App-section-body"
      >
        <div className="footer-top">
          <img src={imgLineArt} alt="img-line" className="img-footer-line" />
          <h2 className="footer-logo text-stroke">
            <span>C</span>
            <span>0</span>
            <span>N</span>
            <span>N</span>
            <span>E</span>
            <span>C</span>
            <span>T</span>
          </h2>
        </div>
        <img
          src={imgArrowDown}
          alt={"img-arrow"}
          className="img-footer-arrow"
        />
        <div className="footer-bottom">
          <div className="footer-contact-wrap">
            <div className="footer-contact-left">
              <h5 className="fancy">Contact US</h5>
              <button
                className="btn btn-yellow btn-signup"
                onClick={() => setmodal(true)}
              >
                Contact Us for details
              </button>
            </div>
            <div className="footer-contact-right">
              <h5>
                Sign up<br></br>for Updates
              </h5>
              <form onSubmit={handleEmailSignup} className="form-signup">
                {" "}
                <input
                  className="form-control"
                  type="email"
                  placeholder="Email Address"
                  name="sinupEmail"
                  value={signupEmail}
                  onChange={(e) => setSignupEmail(e.target.value)}
                  required
                />
                <button className="btn btn-yellow btn-signup" type="submit">
                  Signup
                </button>
              </form>
            </div>
          </div>
        </div>
        <div>
          <div className="footer-strip">
            {[...Array(100)].map((_, index) => (
              <img
                key={index}
                src={imgGameBoss}
                alt="img-gameboss"
                className="img-footer-gameboss"
              />
            ))}
          </div>
          <div className="footer">
            <sapn className="footer-copyright">
              Copyright © {currentYear} <b className="text-green">GAMEBOSS.</b>{" "}
              All Rights Reserved
            </sapn>
            <div className="footer-links">
              <a href="#">Privacy Policy</a>
              <a href="#">Terms of Use</a>
            </div>{" "}
          </div>
        </div>
      </motion.div>{" "}
      {modal && (
        <div className="modal">
          <div className="modal-dialog">
            <div className="modal-content">
              <form onSubmit={handleContactUsSubmit}>
                <div className="modal-body">
                  <div className="form form-Contact">
                    <h6 className="modal-title">Contact US</h6>
                    <div className="form-item">
                      <label className="form-label">Full Name</label>
                      <input
                        type="text"
                        className="form-control"
                        name="fullName"
                        placeholder="Full Name"
                        value={contactUs.fullName}
                        onChange={(e) =>
                          setContactUs({
                            ...contactUs,
                            fullName: e.target.value,
                          })
                        }
                        required
                      />
                    </div>
                    <div className="form-item">
                      <label className="form-label">Email Address</label>
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Email Address"
                        name="email"
                        value={contactUs.email}
                        onChange={(e) =>
                          setContactUs({ ...contactUs, email: e.target.value })
                        }
                        required
                      />
                    </div>
                    <div className="form-item">
                      <label className="form-label">Subject</label>
                      <input
                        type="text"
                        className="form-control"
                        name="subject"
                        placeholder="Subject"
                        value={contactUs.subject}
                        onChange={(e) =>
                          setContactUs({
                            ...contactUs,
                            subject: e.target.value,
                          })
                        }
                        required
                      />
                    </div>

                    <div className="form-item">
                      <label className="form-label">Message</label>
                      <textarea
                        className="form-control"
                        rows="3"
                        name="message"
                        placeholder="Message"
                        value={contactUs.message}
                        onChange={(e) =>
                          setContactUs({
                            ...contactUs,
                            message: e.target.value,
                          })
                        }
                        required
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  {" "}
                  <button type="submit" className="btn btn-light">
                    Send Email
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-light"
                    data-bs-dismiss="modal"
                    onClick={() => setmodal(null)}
                  >
                    Close
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Footer;
