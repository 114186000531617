import React from "react";
import { motion } from "framer-motion";
import imgPriority from "../assets/images/img-access-priority.png";
import imgExclusive from "../assets/images/img-access-exclusive.png";
import imgPersonal from "../assets/images/img-access-person.png";
import imgEarly from "../assets/images/img-access-early.png";

function EarlyAccess() {
  return (
    <div className="App-section App-earlyAccess">
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 0.65, ease: "easeInOut" }}
        viewport={{ once: true }}
        className="App-section-body"
      >
        <div className="App-earlyAccess-pages">
          <div className="App-earlyAccess-page earlyAccess-left ">
            <h2 className="App-section-title">
              <div className="text-stroke">Get</div>
              <span className="text-green-glow">Early Access</span>
            </h2>
            <p className="access-text">
              Don’t miss out! Join our pre-launch waitlist and enjoy exclusive
              rewards, including free trial periods, limited-edition
              merchandise, and special access to our grand opening events.
              Secure your spot in the Game Boss community today.
            </p>
          </div>
          <div className="App-earlyAccess-page App-earlyAccess-page-cards">
            <div className="earlyAccess-wrap">
              <div className="card-earlyAccess">
                <h6 className="card-earlyAccess-title">Priority Booking</h6>
                <img
                  className="img-earlyAccess"
                  src={imgPriority}
                  alt="img-earlyAccess"
                />
                <div className="card card-borderGradient card-earlyAccess">
                  <div className="card-body">
                    <div className="card-text">
                      Reserve gaming stations and private rooms ahead of
                      non-members.
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-earlyAccess">
                <h6 className="card-earlyAccess-title">Exclusive Events</h6>
                <img
                  className="img-earlyAccess"
                  src={imgExclusive}
                  alt="img-earlyAccess"
                />
                <div className="card card-borderGradient card-earlyAccess">
                  <div className="card-body">
                    <div className="card-text">
                      Gain access to members-only tournaments, workshops, and
                      social gatherings.
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-earlyAccess">
                <h6 className="card-earlyAccess-title">
                  Personalized Services
                </h6>
                <img
                  className="img-earlyAccess"
                  src={imgPersonal}
                  alt="img-earlyAccess"
                />
                <div className="card card-borderGradient card-earlyAccess">
                  <div className="card-body">
                    <div className="card-text">
                      Benefit from tailored support and services to enhance your
                      gaming experience.
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-earlyAccess">
                <h6 className="card-earlyAccess-title">Early Access</h6>
                <img
                  className="img-earlyAccess"
                  src={imgEarly}
                  alt="img-earlyAccess"
                />
                <div className="card card-borderGradient card-earlyAccess">
                  <div className="card-body">
                    <div className="card-text">
                      Be the first to try out new features and participate in
                      beta events
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>{" "}
    </div>
  );
}

export default EarlyAccess;
