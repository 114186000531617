import React from "react";
import { useRef } from "react";
import { motion, useScroll, useTransform, useSpring } from "framer-motion";
import imgStrategy from "../assets/images/img-skillsStrategy.jpg";
import imgStream from "../assets/images/img-skillsStreaming.jpg";
import imgTeam from "../assets/images/img-skillsTeam.jpg";

function Skills() {
  var pageShiftTime = window.innerWidth < 700 ? 0.4 : 0.6;

  const scrollRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: scrollRef,
    offset: ["start end", "end start"],
  });
  const scaleSpring = useSpring(scrollYProgress, {
    stiffness: 120,
    damping: 30,
    restDelta: 0.001,
  });
  const moveCards = useTransform(scaleSpring, [0.35, 0.6], ["0px", "-100dvw"]);
  return (
    <motion.div className="App-section App-skills" ref={scrollRef}>
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 1, ease: "easeInOut" }}
        viewport={{ once: true, amount: 0.5 }}
        className="App-section-body"
      >
        <motion.div
          className="App-skills-pages"
          style={{ translateX: moveCards }}
        >
          <div className="App-events-page  App-skills-page-intro">
            <h2 className="App-section-title">
              <div className="text-stroke">Skills</div>
              <span className="text-green-glow">Enhancements</span>
            </h2>
            <p className="skills-text">
              Take your gaming to the next level with our workshops and training
              sessions. Learn from the best in the industry and improve your
              strategies, streaming techniques, and esports management skills
            </p>
          </div>
          <div className="App-skills-page">
            <div className="skills-wrap">
              <div className="card card-borderGradient card-event">
                <img src={imgStrategy} alt="img-event"></img>
                <div className="card-body">
                  <h6 className="card-title">Strategy Mastery</h6>
                  <div className="card-text">
                    Learn advanced strategies for competitive gaming from
                    experienced players and coaches.
                  </div>
                </div>
              </div>
              <div className="card card-borderGradient card-event">
                <img src={imgStream} alt="img-event"></img>
                <div className="card-body">
                  <h6 className="card-title">Streaming Essential</h6>
                  <div className="card-text">
                    Discover the tools and techniques to build and grow your
                    streaming presence
                  </div>
                </div>
              </div>
              <div className="card card-borderGradient card-event">
                <img src={imgTeam} alt="img-event"></img>
                <div className="card-body">
                  <h6 className="card-title">Team Management</h6>
                  <div className="card-text">
                    Master the art of managing and leading your gaming team for
                    maximum performance.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </motion.div>
      </motion.div>{" "}
    </motion.div>
  );
}

export default Skills;
